import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql, Link } from "gatsby"
import { Container, Row, Col } from '../../../components/ui/wrapper'
import { Form } from 'react-bootstrap'
import SectionTitle from '../../../components/ui/section-title'
import Button from '../../../components/ui/button'
import BoxIcon from '../../../components/box-icon/layout-two'
import {
  PoliciesWrapper, BoxIcontitle, BoxIconsubtitle,
  BoxSpanDate, BodyHTMLContent, BoxCurrencySpan, Loader
} from './policies-area.style'
import PageHeader from '../../../components/pageheader'
import Image from '../../../components/image'
import Axios from 'axios'


import { document } from "browser-monads";


const WebinarDescription = ({ pageContext, buttonOneStyle }) => {

  const result = useStaticQuery(graphql`
    query webinarDefQuery {
        allItServicesJson(sort: {order: DESC, fields: id}, filter: {is_featured: {eq: true}, is_webinar: {eq: true}}) {
            edges {
                node {
                    fields {
                      slug
                    }
                    id
                    title
                    excerpt
                    body
                    price
                    interval
                    image {
                        childImageSharp {
                            fluid(maxWidth: 500, maxHeight: 250, quality: 100) {
                                ...GatsbyImageSharpFluid_withWebp
                                presentationWidth
                                presentationHeight
                            }
                        }
                    }
                    icon {
                      svg {
                        publicURL
                      }
                    }
                }
            }
        }
    }
  `);

  // console.log(id);
  const queryString = document.location.search;
  const urlParams = new URLSearchParams(queryString);
  const webinarId = urlParams.get('id');

  const [numberTikets, setNumberTikets] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");


  const serviceData = result.allItServicesJson.edges;

  const [webinarInfo] = serviceData.map(item => item.node).filter(item => item.id == webinarId)
  console.log(webinarInfo);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',

    // These options are needed to round to whole numbers if that's what you want.
    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    const site = "https://betechnology.co/checkout"
    setIsLoading(true);
    Axios.post("https://sandbox-mws.safetypay.com/mpi/api/v1/online-payment-requests", {
      "application_id": "8",
      "expiration_time_minutes": 120,
      "language_code": "ES",
      "merchant_sales_id": "Betech",
      "merchant_set_pay_amount": false,
      "payment_error_url": site,
      "payment_ok_url": site,
      "requested_payment_type": "StandardorBoleto",
      "sales_amount": {
        "currency_code": "COP",
        "value": (price * numberTikets)
      },
      "send_email_shopper": false,
      "transaction_email": "betechnology@outlook.com"
    }, {
      headers: {
        'X-Api-Key': '-----',
        'X-Version': '20200803',
        'Content-Type': 'application/json'
      }
    }).then((res) => {
      if (res.status === 201) {
        // console.log(res)
        document.location = res.data.gateway_token_url;
      }

    }).catch((err) => {
      setIsLoading(false);
      setFormErrors("ha ocurrido un problema al procesar su solicitud. Por favor, inténtelo de nuevo más tarde.")
    })



  }

  const price = webinarInfo?.price || 200;
  const interval = webinarInfo?.interval || 15;
  var currentDate = new Date();
  var NextMonth = currentDate.getMonth() + 2;
  var stringDate = `${interval < 10 ? `0${interval}` : interval}/${NextMonth < 10 ? `0${NextMonth}` : NextMonth}/${currentDate.getFullYear()}`;


  return (<>
    <PageHeader pageContext={pageContext} location={
      { pathname: "" }
    } title={webinarInfo?.title || ""} />

    <PoliciesWrapper id="webinar-conten">
      <Container>
        <Row>
          <Col md={8} class="mr-1">
            <BoxIconsubtitle>Webinar</BoxIconsubtitle>
            <BoxIcontitle>{webinarInfo?.title || ""}</BoxIcontitle>
            <BoxSpanDate>{stringDate} 2:00 PM COL</BoxSpanDate>
            <BodyHTMLContent dangerouslySetInnerHTML={{ __html: webinarInfo?.body || "" }}
            ></BodyHTMLContent>
          </Col>
          <Col md={4}>
            {/* <BoxImage src={webinarInfo?.image }/> */}
            <Image fluid={webinarInfo?.image.childImageSharp.fluid} alt="About Banner" />
            {!isLoading &&
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={12} className='mb-2'> <h4>Correo</h4> </Col>
                  <Col md={12} >
                    <Form.Control required type='email' placeholder="email@gmail.com" />
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className='mb-2'> <h4>Entradas</h4> </Col>
                  <Col md={4} className='mb-3'>
                    <Form.Control required type='number' value={numberTikets} onChange={(e) => setNumberTikets(parseInt(e.currentTarget.value))} />
                  </Col>
                  <Col md={8}>
                    <BoxCurrencySpan>
                      {formatter.format(price * numberTikets)} USD
                    </BoxCurrencySpan>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Form.Check // prettier-ignore
                      type="checkbox"
                      label="He leído y acepto la Política de Privacidad"
                      required
                    />
                  </Col>
                  <Col md={12} className="pt-3 text-center">
                    <Button className="m-auto" type="submit"  {...buttonOneStyle}>Comprar</Button>
                  </Col>
                </Row>
              </Form>
            }

            {isLoading &&
              <Row>
                <Col className="pt-2">
                  <p className='mb-4'>
                    <strong>Por favor, no refresque la ventana</strong> <br />
                    Estamos procesando su solicitud. Le agradecemos su paciencia y le pedimos que no cierre ni actualice la ventana para evitar interrupciones en el servicio.
                  </p>
                  <Loader />
                </Col>
              </Row>
            }



          </Col>
        </Row>
      </Container>
    </PoliciesWrapper>
  </>
  )
}


WebinarDescription.defaultProps = {
  policyNane: "",
  sectionTitleStyle: {
    mb: '40px',
    responsive: {
      small: {
        mb: '30px'
      }
    }
  },
  buttonOneStyle: {
    m: '10px',
    hover: '2'
  },
  buttonTwoStyle: {
    m: '10px',
    varient: 'outlined'
  }
}

export default WebinarDescription;