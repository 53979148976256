import React from "react"
import SEO from "../components/seo"
import Layout from '../containers/layout/layout'
// import Header from '../containers/layout/header/header-three'
// import Header from '../containers/layout/header/header-four'
import Header from '../containers/layout/header/header-three/indexSub'

import Footer from '../containers/layout/footer/footer-one'
// import HeroArea from '../containers/index-services/hero-area'
//import HeroArea from '../containers/index-cybersecurity/hero-area'
//  import HeroArea from '../containers/index-appointment/hero-area'
import PageHeader from '../components/pageheader'
import FeaturesArea from '../containers/index-services/features-area'
import AboutArea from '../containers/index-services/about-area'
import ServicesArea from '../containers/index-services/services-area'
import GradationArea from '../containers/index-services/gradation-area'
import CtaArea from '../containers/global/cta-area/section-one'
import CaseStudyArea from '../containers/index-services/case-study-area'
import BlogArea from '../containers/index-services/blog-area'
import ContactArea from '../containers/global/contact-area/contact-four'
import ClientsArea from '../containers/global/clients-area'

// import Features from '../containers/elements/box-image/section-one'
// import Features from '../containers/elements/box-image/section-seven'
import Features from '../containers/elements/box-image/section-four'


import AboutServiceWrap from '../containers/index-infotechno/about-service-wrap'
import ServicesAreaFeatures from '../containers/index-infotechno/services-area'
import { Container, Row, Col } from "react-bootstrap"
import Webinar from '../containers/index-services/webinars-area/webinar'


// import AboutArea from '../containers/index-infotechno/about-area'


const IndexServices = ({ location, pageContext }) => (
  <Layout location={location}>
    <SEO />
    <Header transparent={false} />
    <main className="site-wrapper-reveal">
      <Webinar pageContext={pageContext} />
    </main>

  </Layout>
)

export default IndexServices
