import styled from 'styled-components';
import {device} from '../../../theme'
import { rotation } from '../../../assets/css/animations'

export const PoliciesWrapper = styled.section `
    padding-top: 100px;
    padding-bottom: 90px;
    background-color: #F8F8F8;
    
    ul{
        padding-left: 1.5rem;    
        margin-botton: 1rem;
        list-style: disc;
    }

    ul li{

    }

    h3{
        font-size: 14pt;
    }
    
    h4{
        font-size: 12pt;
    }

    h3,h4 {
        margin-top: 1rem;
    }

    p{
        margin: 0;
        margin-top: 0.3rem;
    }

    blockquote{
        margin: 54px 65px 44px 70px;
        padding: 0 0 0 20px;
        font-size: 18px;
        line-height: 1.78;
        font-weight: 600;
        border-left: 4px solid #eee;
        border-left-color: #d2a98e;
        position: relative;
        color: #333;
        @media ${device.small}{
            font-size: 16px;
            margin: 50px 70px 44px;
        }
        @media ${device.xsmall}{
            margin: 40px 30px;
        }
    }

    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 70px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 50px;
    }
`;

export const SectionBottom = styled.div `
    margin-top: 30px;
`;


export const BoxIcontitle = styled.h5`
    font-weight: 500;
    line-height: 1.25;
    margin-bottom: 12px;
`;

export const BoxIconsubtitle = styled.h6`
    color: #ababab;
    font-weight: 400;
    margin-top: -5px;
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 14px;
    -webkit-letter-spacing: 3px;
    -moz-letter-spacing: 3px;
    -ms-letter-spacing: 3px;
    letter-spacing: 3px;
    line-height: 1.43;
`;
export const BoxSpanDate = styled.span`
    color: #e23a0c;
`;

export const BoxCurrencySpan = styled.span`
    width: 100%;
    display: block;
    text-align: right;
    font-size: 14pt;
    color: #0097A7;
`;

export const Loader = styled.span`
    width: 30px;
    height: 30px;
    border: 5px solid #0097A7;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: block;
    margin: auto;
    box-sizing: border-box;
    animation: ${rotation} 1s linear infinite;
`;


export const BodyHTMLContent = styled.div`
    padding-right: 1rem;
`;
